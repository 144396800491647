<template>
  <div ref="thumbnail" class="flex flex-col gap-y-2">
    <div
      :style="posterStyle"
      class="h-full bg-gray-900 w-full relative thumbnail square_thumbnail rounded-lg aspect-video bg-center bg-no-repeat bg-cover"
    >
      <div class="w-full flex gap-x-1 items-center pt-2 pe-2 justify-end">
        <UiTagLive v-if="useIsLive(item)"></UiTagLive>
        <UiTagPlus v-if="item.showPlusLabel"></UiTagPlus>
      </div>
    </div>
    <div class="flex flex-col">
      <h4
        class="text-title-small text-white opacity-[0.87] font-bold line-clamp-2"
      >
        {{ label }}
      </h4>
      <small v-if="durationPretty" class="text-title-small text-white opacity-50">
        {{ durationPretty }}
      </small>
    </div>
  </div>
</template>

<script setup>
import { useIntersectionObserver } from "@vueuse/core";

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
});

const { asDurationPretty } = useDateTime();

const thumbnail = ref();
const isVisible = ref(false);
const posterStyle = ref();

onMounted(() => {
  posterStyle.value = useFallbackImage().style;
});

const imageToShow = computed(() => props.item.images?.squareimage);

const { stop } = useIntersectionObserver(
  thumbnail,
  ([{ isIntersecting }], observerElement) => {
    isVisible.value = isIntersecting;
  }
);

watch(isVisible, () => {
  if (isVisible.value && imageToShow.value) {
    posterStyle.value = { backgroundImage: `url('${imageToShow.value}')` };
    stop();
  }
});

const label = computed(() => useFindLabel(props.item));

const durationPretty = computed(() => {
  if (!props.item.duration) return null;
  return asDurationPretty(props.item.duration / 1000);
});
</script>

<style lang="scss" scoped></style>
