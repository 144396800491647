<template>
  <div ref="thumbnail" class="flex flex-col gap-y-2">
    <div
      :style="posterStyle"
      class="h-full w-full thumbnail relative big rounded-lg aspect-video bg-center bg-no-repeat bg-contain"
    >
      <div class="w-full flex gap-x-1 items-center pt-2 pe-2 justify-end">
        <UiTagLive v-if="useIsLive(item)"></UiTagLive>
        <UiTagPlus v-if="item.showPlusLabel"></UiTagPlus>
      </div>
    </div>
    <div class="flex flex-col">
      <!-- remove this filtering logic,  just pick the first item.name   in genreEntityList -->
      <small class="text-title-small text-white opacity-50">
        {{ item.genreEntityList[0]?.name }}
      </small>
      <h4 class="text-title-small text-white opacity-[0.87] font-bold">
        {{ label }}
      </h4>
      <small v-if="item.durationPretty" class="text-title-small text-white opacity-50">
        {{ item.durationPretty }}
      </small>
      <small
        v-if="isMajidSong && item.duration"
        class="text-start text-label-medium md:text-title-small text-white opacity-50"
      >
        {{ songDurationPretty }}
      </small>
    </div>
  </div>
</template>

<script setup>
import { useIntersectionObserver } from "@vueuse/core";
const { asSongDuration } = useDateTime();

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
});

const thumbnail = ref();
const isVisible = ref(false);
const posterStyle = ref();

onMounted(() => {
  posterStyle.value = useFallbackImage().style;
});

const imageToShow = computed(() => {
  /**
   * for live program:
   * in misc carousel: if no horizontalImage in place, use channel logo
   */
  if (
    props.item.type == "LiveProgram" &&
    Object.keys(props.item).includes("channel")
  ) {
    const images = useMediaAssets(props.item.channel);
    return images.logo;
  }

  return props.item.images?.horizontalimage;
});

const { stop } = useIntersectionObserver(
  thumbnail,
  ([{ isIntersecting }], observerElement) => {
    isVisible.value = isIntersecting;
  }
);

const isMajidSong = computed(() => {
  return props.item.type == "Movie" && props.item.contentType == "Majid Songs";
});

const songDurationPretty = computed(() => {
  return asSongDuration(props.item.duration / 1000);
});

watch(isVisible, () => {
  if (isVisible.value && imageToShow.value) {
    posterStyle.value = { backgroundImage: `url('${imageToShow.value}')` };
    stop();
  }
});

const label = computed(() => useFindLabel(props.item));
</script>

<style lang="scss" scoped></style>
